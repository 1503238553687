<template>
  <v-dialog v-model="bDialog" max-width="500" persistent>
    <template #activator="{ on: dialog }">
      <!--        :max-width="$vuetify.breakpoint.mdAndDown ? '200px' : '250px'"-->
      <v-list
        class="py-0 fill-height"
        color="transparent"
        flat
        :max-width="maxWidth"
      >
        <v-menu
          bottom
          close-delay="500"
          offset-overflow
          offset-y
          open-on-hover
          rounded="t-0 b-lg"
        >
          <template #activator="{ on: menu }">
            <v-list-item
              class="fill-height"
              dense
              v-on="{ ...menu, ...dialog }"
            >
              <name-with-avatar
                :name="title"
                :path="avatar"
                fill-height
                hide-avatar-on-mobile
                ref="companyPicker"
              >
                <template v-if="branch.firm" #subtitle>
                  <span
                    id="gw-company-picker"
                    class="text-caption"
                    v-text="branch.firm.name"
                  />
                </template>
              </name-with-avatar>
            </v-list-item>
          </template>

          <v-list dense nav :max-width="maxWidth">
            <v-subheader v-text="title" />
            <template v-for="branchItem in branchList">
              <branch-item-preview
                :key="`branch-item-${branchItem.id}`"
                :disabled="branchItem.id === branch.id"
                :item="branchItem"
                hide-icon
                hide-subtitle
                icon-size="1em"
                @click="setBranch(branchItem.id)"
              />
            </template>

            <v-list-item @click="openSettingsDialog">
              <v-list-item-icon class="mr-2">
                <icon-settings />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$t('company.settings')" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
      <company-settings-dialog v-model="bSettingsDialog" />
    </template>

    <v-card :loading="loading">
      <v-list v-if="company.id" class="active">
        <v-list-item>
          <v-list-item-avatar color="my-1 mr-2">
            <v-img v-if="avatar" :alt="company.firm.name" :src="avatar" />
            <icon-camera v-else outlined size="1.8rem" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="company.firm.name" />
            <v-list-item-subtitle v-text="branch.name" />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-text v-if="userIsAdmin">
        <v-text-field
          ref="searchField"
          v-model="sSearch"
          :label="$t('search.company')"
          :placeholder="$t('search.company')"
        ></v-text-field>
      </v-card-text>

      <v-card-text v-if="obCompanyCollection.length">
        <v-list color="transparent" rounded>
          <template v-for="(item, index) in obCompanyCollection.getModels()">
            <v-list-item
              :key="`companies-list-item-${index}`"
              :class="{ 'v-list-item--active': company.id == item.id }"
              @click="setCompany(item)"
            >
              <v-list-item-avatar color="grey lighten-3">
                <v-img
                  v-if="item.preview_image"
                  :alt="item.firm.name"
                  :src="item.preview_image"
                />
                <icon-camera v-else outlined size="1.8rem" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.firm.name" />
                <v-list-item-subtitle v-text="item.firm.email" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!closable"
          color="primary"
          depressed
          small
          @click="bDialog = false"
        >
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import type { CompanyData } from "@planetadeleste/vue-mc-gw";
import { CompanyCollection } from "@planetadeleste/vue-mc-gw";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { AppModule } from "@/store/app";
import CompanySettingsDialog from "@/modules/companies/components/CompanySettingsDialog.vue";
import { AuthModule } from "@/store/auth";
import type { DebounceFunction } from "@/plugins/helpers";
import { get } from "lodash";
import BranchItemPreview from "@/modules/companies/components/BranchItemPreview.vue";

@Component({
  components: { BranchItemPreview, NameWithAvatar, CompanySettingsDialog },
})
export default class CompanyPicker extends Vue {
  bDialog = false;
  bSettingsDialog = false;
  sSearch = "";
  fnDebounceSearch!: DebounceFunction;
  obCompanyCollection = new CompanyCollection();
  elObserver!: ResizeObserver;
  maxWidth = 300;

  get loading() {
    return this.obCompanyCollection.loading || AppModule.categories.loading;
  }

  get user() {
    return AuthModule.user;
  }

  get userIsCompany() {
    return AuthModule.isCompany;
  }

  get userIsAdmin() {
    return AuthModule.isAdmin;
  }

  get company() {
    return AppModule.company;
  }

  get branch() {
    return AppModule.branch;
  }

  get branchList() {
    if (!this.company?.branches?.length) {
      return [];
    }

    if (this.userIsAdmin) {
      return this.company.branches;
    }

    const availableBranches: number[] = get(this.user, "branch_list_id", []);
    return this.$_.filter(this.company.branches, (obBranch) => {
      return availableBranches.includes(obBranch.id);
    });
  }

  get title() {
    return this.company.id ? this.company.firm.name : this.$t("pick.company");
  }

  get avatar() {
    return this.company.preview_image;
  }

  get closable() {
    return !!this.company.id || !this.obCompanyCollection.length;
  }

  @Ref("companyPicker") readonly obCompanyPicker!: InstanceType<
    typeof NameWithAvatar
  >;

  @Watch("sSearch")
  onSearch() {
    this.fnDebounceSearch();
  }

  @Watch("bDialog")
  onClose(bVal: boolean) {
    if (!bVal && this.userIsAdmin) {
      this.sSearch = "";
      this.obCompanyCollection.clear();
    } else if (bVal) {
      this.search();
    }
  }

  setMaxWidth() {
    const elList = this.obCompanyPicker?.$el;

    if (!elList) {
      return;
    }

    this.maxWidth =
      elList
        .querySelector(".v-list-item__title > span")
        ?.getBoundingClientRect().width ?? 300;
    this.maxWidth += 80;
    const elAvatar = elList.querySelector(".v-list-item__avatar");

    if (elAvatar) {
      this.maxWidth += elAvatar.getBoundingClientRect().width;
    }
  }

  created() {
    this.fnDebounceSearch = this.$_.debounce(this.search, 500);
  }

  mounted() {
    this.$nextTick(async () => {
      if (this.obCompanyPicker?.$el) {
        this.elObserver = new ResizeObserver(() => {
          this.setMaxWidth();
        });
        this.elObserver.observe(this.obCompanyPicker.$el);
      }
      await this.search();

      if (!this.obCompanyCollection.length) {
        if (this.$route.name && this.$route.name !== "companies.create") {
          await this.$router.push({ name: "companies.create" });
        }

        return;
      }

      if (!localStorage.getItem("cid")) {
        this.bDialog = true;
      }
    });
  }

  beforeDestroy() {
    if (this.elObserver) {
      this.elObserver.disconnect();
    }
  }

  async search() {
    this.obCompanyCollection.clear();
    this.obCompanyCollection.page(1);

    if (this.userIsCompany) {
      this.obCompanyCollection.byUser(this.user.id);
    }

    if (this.sSearch.length) {
      this.obCompanyCollection.filterBy({ search: this.sSearch });
    } else {
      this.obCompanyCollection.filterBy({ take: 5 });
    }

    await this.obCompanyCollection.fetch();
  }

  async fetchCategories() {
    await AppModule.loadCategories();
  }

  unsetCompany() {
    AppModule.unsetCompany();
    this.fetchCategories();
  }

  async setCompany(obCompany: CompanyData) {
    await AppModule.pickCompany(obCompany);
    await this.fetchCategories();
    this.bDialog = false;
  }

  setBranch(iBranchID: number) {
    AppModule.pickBranch(iBranchID);
  }

  openSettingsDialog() {
    this.bSettingsDialog = true;
  }
}
</script>
