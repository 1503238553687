<template>
  <v-app-bar app clipped-left dark fixed flat>
    <div
      class="w-full h-full d-flex justify-space-between align-center ma-0 pa-0"
    >
      <div class="d-flex h-full align-center">
        <v-app-bar-nav-icon @click="setDrawer">
          <icon-menu outlined size="1.5rem" />
        </v-app-bar-nav-icon>
        <v-toolbar-title style="min-width: 80px" class="ml-2">
          <router-link :to="{ name: 'home' }">
            <img
              class="d-md-none"
              src="@image/logo-sm.png"
              alt="Gestion Web"
              style="max-height: 45px; width: auto"
            />
            <img
              class="d-none d-md-block"
              src="@image/logo.png"
              alt="Gestion Web"
              style="max-height: 45px; width: auto"
            />
          </router-link>
        </v-toolbar-title>
      </div>

      <div class="d-flex h-full">
        <invoice-btn-create />
        <v-divider class="d-none d-md-block" vertical />
        <company-picker class="flex-grow-0 flex-shrink-0" />
      </div>

      <div class="d-flex align-center h-full">
        <company-balance class="d-none d-md-block" />

        <v-btn text @click="setRightDrawer" class="h-full">
          <profile-nav />
        </v-btn>
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

import ProfileNav from "@/layout/components/ProfileNav.vue";
import CompanyPicker from "@/modules/companies/components/CompanyPicker.vue";
import CompanyBalance from "@/modules/companies/components/CompanyBalance.vue";
import InvoiceBtnCreate from "@/modules/invoices/components/InvoiceBtnCreate.vue";

@Component({
  components: { InvoiceBtnCreate, ProfileNav, CompanyPicker, CompanyBalance },
})
export default class AppBar extends Vue {
  @Prop(Boolean) readonly drawer!: boolean;
  @Prop(Boolean) readonly rightDrawer!: boolean;

  setDrawer() {
    return this.$emit("change:drawer", !this.drawer);
  }

  setRightDrawer() {
    return this.$emit("change:right-drawer", !this.rightDrawer);
  }
}
</script>
