import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store/index";
import {
  ProductCollection,
  type ProductData,
} from "@planetadeleste/vue-mc-shopaholic";
import type { OfferData } from "@planetadeleste/vue-mc-gw";
import { sortBy } from "lodash";

@Module({
  name: "product",
  dynamic: true,
  namespaced: true,
  store: store,
  preserveState: localStorage.getItem("vuex") !== null,
})
class ProductStore extends VuexModule {
  private loading: boolean = false;

  _items: Partial<ProductData>[] = [];

  get items(): Partial<ProductData>[] {
    return sortBy(this._items, "code");
  }

  get filteredItems() {
    return (obFilters: Record<string, any> = {}) => {
      return this._items.filter((obItem) => {
        let bFound = false;

        Object.entries(obFilters).forEach(([key, value]) => {
          switch (key) {
            case "priceListId":
              bFound =
                (obItem.offers as OfferData[])?.filter(
                  (obOffer: OfferData) => obOffer.price_list_id === value
                ).length > 0;
              break;

            case "sell":
              bFound = bFound && !!obItem?.product_gw?.is_sell;
              break;

            case "service":
              bFound = bFound && !!obItem?.product_gw?.is_service;
              break;

            case "withoutGenerated":
              bFound = bFound && !obItem?.product_gw?.is_auto_generated;
              break;

            case "taxtypeId":
              bFound = bFound && obItem?.product_gw?.taxtype_id === value;
              break;

            case "search":
              bFound =
                bFound &&
                (!!obItem?.name?.toLowerCase().includes(value.toLowerCase()) ||
                  !!obItem?.code?.toLowerCase().includes(value.toLowerCase()));
              break;
          }
        });

        return bFound;
      });
    };
  }

  get collection() {
    const obCollection = new ProductCollection();
    obCollection.add(this.items);
    return obCollection;
  }

  get models() {
    return this.collection.getModels();
  }

  @Mutation
  setItems(items: Partial<ProductData>[]) {
    this._items = items;
  }

  @Mutation
  setLoadingState(state: boolean) {
    this.loading = state;
  }

  @Action
  async load(obFilters: Record<string, any> = {}, limit: number = 10000) {
    if (this.loading) return;

    this.setLoadingState(true);

    try {
      const obCollection = new ProductCollection();
      await obCollection.filterBy(obFilters).limit(limit).fetch();
      this.setItems(obCollection.getModelList() as ProductData[]);
    } catch (e) {
      console.error("An error occurred:", e);
    }

    this.setLoadingState(false);
  }

  @Mutation
  reset() {
    this._items = [];
  }
}

export const ProductModule = getModule(ProductStore);
